<template>
	<w-layout column fill-height>
		<w-flex shrink>
			<w-layout row>
				<w-flex xs12 md6>
					<v-text-field v-model="search" :label="$t('actions.search')" maxlength="191" prepend-icon="search" />
				</w-flex>
			</w-layout>
		</w-flex>
		<w-flex scroll-y>
			<v-data-table :headers="headers" :items="searchedAccountingFirms" item-key="id" class="elevation-1" disable-initial-sort :pagination.sync="pagination">
				<template v-slot:headers="props">
					<tr>
						<th v-for="header in props.headers" :key="header.id">{{ header.text }}</th>
					</tr>
				</template>
				<template v-slot:items="props">
					<td>{{ props.item.name }}</td>
					<td v-for="item in modules" :key="item.id">
						<v-layout>
							<w-flex>
								<v-checkbox
									:ref="'checkboxmodule_accountingFirm:' + props.item.id + '_module:' + item.id"
									v-model="props.item.modulesState[item.id]"
									:value="props.item.modulesState[item.id]"
									color="primary"
									class="justify-center"
									hide-details
									@change="setSubscription(props.item, item, props.item.modulesState[item.id])"
								/>
							</w-flex>
							<w-flex v-if="item.parameters_formatter !== null && props.item.modulesState[item.id]">
								<v-tooltip left>
									<template v-slot:activator="{ on }">
										<span v-on="on" @click="setSubscription(props.item, item, true, true)"><span class="fas fa-fw fa-pencil-alt"></span></span>
									</template>
									<span>Éditer les paramètres personnalisés</span>
								</v-tooltip>
							</w-flex>
							<w-flex v-else><span class="fas fa-fw"></span></w-flex>
						</v-layout>
					</td>
				</template>
			</v-data-table>
		</w-flex>

		<ModulesParametersConfigDialog
			ref="parametersConfigDialog"
			:inputs="dialogModuleParametersConfig.inputs"
			:default-values="dialogModuleParametersConfig.defaultValues"
			@closeParametersConfigDialog="closeParametersConfigDialog"
			@saveParametersConfigDialog="saveParametersConfigDialog"
		/>
	</w-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'ModulesSubscriptionsManager',
	components: {
		ModulesParametersConfigDialog: () => ({
			component: import('@/components/SuperAdmin/Modules/ModulesParametersConfigDialog')
		})
	},
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			accountingFirms: [],
			headers: [{ align: 'center', text: this.$t('sa.accounting_firm') }],
			modules: [],
			pagination: {
				rowsPerPage: 10
			},
			search: null,

			dialogModuleParametersConfig: {
				show: false,
				params: {},
				inputs: [],
				defaultValues: {}
			}
		}
	},

	computed: {
		searchedAccountingFirms: function () {
			let result = []
			if (this.search) {
				const searchedAccountingFirm = this.search
				result = this.accountingFirms.filter(accountingFirm => accountingFirm.name.toLowerCase().includes(searchedAccountingFirm.toLowerCase()))
			} else {
				result = this.accountingFirms
			}
			return result
		}
	},

	created: function () {
		this.service.getModulesSubscriptionsContent().then(res => {
			this.modules = res.headers.map(item => {
				return {
					align: 'center',
					id: item.id,
					text: `${item.title} (${item.name})`,
					parameters_formatter:
						item.parameters_formatter !== null && typeof item.parameters_formatter === 'string'
							? JSON.parse(item.parameters_formatter)
							: item.parameters_formatter
				}
			})
			this.headers = this.headers.concat(this.modules)

			// Add new array to manage moduleState of module
			this.accountingFirms = res.accounting_firms.map(accountingFirm => {
				accountingFirm.modulesState = []

				this.modules.forEach(module => {
					const moduleFound = accountingFirm.modules.filter((mod) => mod.id === module.id).pop()
					accountingFirm.modulesState[module.id] = !!moduleFound

					if (moduleFound === undefined) {
						accountingFirm.modules.push({
							id: module.id,
							is_active: false,
							parameters: {},
							parameters_formatter: module.parameters_formatter
						})
					}
				})

				return accountingFirm
			})
		})
	},

	methods: {
		// -----------
		// ModulesParametersConfigDialog functions
		// -----------

		/**
		 * Function called on "save" event of dialog
		 * — We sent the "subscribe" event to API for module
		 *
		 * @param array parameters. Required.
		 */
		saveParametersConfigDialog: function (parameters) {
			this.service
				.setSubscription(
					this.dialogModuleParametersConfig.params.accountingFirmId,
					this.dialogModuleParametersConfig.params.module.id,
					this.dialogModuleParametersConfig.params.is_active,
					parameters
				)
				.then(() => {
					this.$refs.parametersConfigDialog.hide()

					let moduleId = this.dialogModuleParametersConfig.params.module.id,
						accountingFirm = this.getAccountingFirm(this.dialogModuleParametersConfig.params.accountingFirmId)

					accountingFirm.modules[moduleId].parameters = parameters
				})
		},

		/**
		 * Function called on "abord" event of dialog
		 * — We uncheck the input
		 * — We emit a Snackbar with info
		 *
		 */
		closeParametersConfigDialog: function () {
			// uncheck the input
			if (this.dialogModuleParametersConfig.params.editionMode === false) {
				this.uncheckAccountingFirmModule(this.dialogModuleParametersConfig.params.accountingFirmId, this.dialogModuleParametersConfig.params.module.id)

				this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, this.$tc('modules_custom_parameters.messages.abort_module_subscription'), 0)
			} else {
				this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, this.$tc('modules_custom_parameters.messages.abort_module_edition'), 0)
			}

			this.$refs.parametersConfigDialog.hide()
		},

		getAccountingFirm (accountingFirmId) {
			return this.accountingFirms.find(item => item.id == accountingFirmId)
		},

		uncheckAccountingFirmModule (accountingFirmId, moduleId) {
			let accountingFirm = this.getAccountingFirm(accountingFirmId)
			accountingFirm.modulesState[moduleId] = false

			this.$refs['checkboxmodule_accountingFirm:' + accountingFirmId + '_module:' + moduleId][0].lazyValue = accountingFirm.modulesState[moduleId]
		},

		setSubscription: function (accountingFirm, module, isActive, edit) {
			if (edit === undefined) {
				edit = false
			}

			if (isActive === true && module.parameters_formatter !== undefined && module.parameters_formatter !== false && module.parameters_formatter !== null) {
				// Open the dialogModuleParametersConfig
				this.dialogModuleParametersConfig.show = true

				// Set needed values for calling setSubscription after parameters saving
				this.dialogModuleParametersConfig.params = {
					module: module,
					accountingFirmId: accountingFirm.id,
					is_active: isActive,
					editionMode: edit
				}

				if (accountingFirm.modules[module.id] !== undefined) {
					this.dialogModuleParametersConfig.defaultValues =
						typeof accountingFirm.modules[module.id].parameters === 'string'
							? JSON.parse(accountingFirm.modules[module.id].parameters)
							: accountingFirm.modules[module.id].parameters
				} else {
					this.dialogModuleParametersConfig.defaultValues = null
				}

				this.dialogModuleParametersConfig.inputs = module.parameters_formatter

				// Init the dialogModuleParametersConfig
				this.$refs.parametersConfigDialog.show()
			} else {
				this.service.setSubscription(accountingFirm.id, module.id, isActive)
			}
		}
	}
}
</script>
